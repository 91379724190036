import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
  row: FelaCSS;
  section: FelaCSS;
  tabWrap: FelaCSS;
  taskWrapNoVenue: FelaCSS;
  body: FelaCSS;
  rightSection: Record<string, FelaCSS>;
}

const styles = (): IStyles => ({
  body: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },

  wrapper: {
    backgroundColor: colors.space04,
    alignContent: 'center',
    paddingTop: '24px',
    width: '100%',
    paddingRight: '16px',
    paddingLeft: '16px',
    flexGrow: 1,
    justifyContent: 'flex-start',

    maxWidthXsMax: {
      paddingBottom: '50px',
      minHeight: '600px',
    },
  },

  row: {
    alignItems: 'stretch',
    alignSelf: 'center',
    flexDirection: 'column',
    maxWidth: '343px',
    width: '100%',

    maxWidthXsMax: {
      flexDirection: 'column',
    },

    desktopDesign: {
      flexDirection: 'row',
      maxWidth: '100%',
      width: 'auto',
    },
  },

  section: {
    ':nth-child(odd)': {
      paddingRight: '20px',

      maxWidthSmMax: {
        paddingRight: '0',
      },
    },

    ':nth-child(even)': {
      paddingLeft: '20px',

      maxWidthSmMax: {
        paddingLeft: '0',
      },
    },
  },

  tabWrap: {
    flexShrink: 0,
    width: '50%',

    ':nth-child(odd)': {
      paddingRight: '8px',
    },

    ':nth-child(even)': {
      paddingLeft: '8px',
    },
  },

  taskWrapNoVenue: {
    marginTop: '61px',

    maxWidthSmMax: {
      marginTop: '0',
    },
  },

  rightSection: { '> a': { flexGrow: 1 }, '> a > div': { flexGrow: 1 } },
});

export default styles;
