import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconLocation = ({ id, color, width = 16, style, ...restProps }: IProps) => {
  const viewBoxWidth = 16;
  const viewBoxHeight = 20;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}>
      <path
        fill={iconColor}
        d="M8 3.7c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4zm0 6.4c-1.4 0-2.5-1.1-2.5-2.4S6.6 5.2 8 5.2s2.5 1.1 2.5 2.4-1.1 2.5-2.5 2.5z"
      />
      <path
        fill={iconColor}
        d="M13.8 2.4C12.3.9 10.2 0 8 0 3.6 0 0 3.4 0 7.6c0 1.4.4 2.8 1.2 4l6.2 8.1c.1.2.4.3.6.3.2 0 .5-.1.6-.3l6.2-8.2v-.1c.8-1.2 1.2-2.6 1.2-3.9 0-1.9-.7-3.7-2.2-5.1zm-.3 8.2L8 17.9l-5.5-7.3c-.6-.9-.9-2-.9-3.1 0-3.3 2.9-6 6.5-6 1.8 0 3.4.7 4.7 1.9 1.1 1.1 1.7 2.6 1.7 4.1-.1 1.1-.4 2.1-1 3.1z"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconLocation;
