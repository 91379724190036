import React, { FunctionComponent } from 'react';
import { Text } from '@bridebook/ui';
import componentStyles from './section-heading.style';

const SectionHeading: FunctionComponent = ({ children }) => {
  const styles = componentStyles();

  return <Text style={styles.wrapper}>{children}</Text>;
};

export default SectionHeading;
