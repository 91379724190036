import { colors } from '../../themes/variables';
import { FelaCSS } from '../fela/flowtypes';

interface StyleTypes {
  wrapper: FelaCSS;
  overlay: FelaCSS;
  location: FelaCSS;
  iconLocation: FelaCSS;
  weddingVenue: FelaCSS;
}

const styles = (): StyleTypes => ({
  wrapper: {
    borderRadius: '4px',
    backgroundColor: colors.white,
    boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.15)',
    overflow: 'hidden',
  },

  overlay: {
    position: 'absolute',
    zIndex: 2,
    paddingLeft: '10px',
    paddingTop: '15px',
    flexDirection: 'row',
    minWidth: '343px',
    width: '100%',
    height: '170px',
    backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))',
  },

  location: {
    paddingLeft: '3px',
    color: 'white',
    fontDefaultSemiBold: 12,
    borderRadius: '4px',
  },

  iconLocation: {
    alignSelf: 'flex-start',
    marginTop: '3px',
  },

  weddingVenue: {
    alignContent: 'center',
    justifyContent: 'center',
    height: '40px',
    paddingRight: '12px',
    paddingLeft: '12px',
  },
});

export default styles;
