import { useRouter } from 'next/router';
import React from 'react';
import { SupplierCard } from '@bridebook/ui';
import { getRoutingLocaleForBB } from 'lib/i18n/get-bb-locale';
import { IMicrositeWedding } from 'lib/types';

interface IProps {
  location: string;
  weddingVenue?: IMicrositeWedding['weddingVenue'];
}

const SupplierCardWrap = ({ location, weddingVenue }: IProps) => {
  const { locale } = useRouter();

  if (weddingVenue?.publicId) {
    const slug = (weddingVenue.name || '').replace(/\s+/g, '-').toLowerCase();

    <SupplierCard
      location={location}
      routingLocale={getRoutingLocaleForBB(locale)}
      url={[slug, weddingVenue.publicId].join('-')}
      weddingVenue={weddingVenue}
    />;
  }

  return (
    <SupplierCard
      location={location}
      routingLocale={getRoutingLocaleForBB(locale)}
      weddingVenue={weddingVenue}
    />
  );
};

export default SupplierCardWrap;
