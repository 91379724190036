import { FelaCSS, colors } from '@bridebook/ui';

const getColor = (color: string) => (color === 'green' ? colors.mint : colors.blueCrush);

interface IStyles {
  wrapper: FelaCSS;
  column: FelaCSS;
  text: FelaCSS;
  subHeader: FelaCSS;
}

const styles = ({ color }: { color: string }): IStyles => ({
  wrapper: {
    marginBottom: '16px',
    width: '100%',
    height: '70px',
    borderRadius: '2px',
    backgroundColor: colors.white,
    boxShadow: `0 2px 8px 0 rgba(0, 0, 0, 0.15), inset 0 -3px 0 0 #6495ED`,
    alignContent: 'center',
    justifyContent: 'center',

    desktopDesign: {
      marginBottom: '24px',
      height: '93px',
    },
  },

  column: {
    flexDirection: 'column',
    justifyContent: 'center',

    maxWidthXsMax: {
      flexDirection: 'row',
    },
  },

  text: {
    fontDefault: 14,
    fontStretch: 'normal',
    lineHeight: '17px',
    textAlign: 'center',
    marginTop: '10px',
    color: getColor(color),
    maxWidthXsMax: {
      marginTop: '0',
      marginLeft: '6px',
    },
  },

  subHeader: {
    fontDefaultSemiBold: 14,
    lineHeight: '16px',
    textAlign: 'center',
    color: colors.space60,
  },
});

export default styles;
