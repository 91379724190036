import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import { RoutingLocales } from '@bridebook/toolbox';
import getDaysLeft from '@bridebook/toolbox/src/datepicker/get-days-left';
import { Box, Text } from '@bridebook/ui';
import { getDatepickerTitle } from 'lib/i18n/date-utils';
import { IMicrositeWedding } from 'lib/types';
import DateCard from './dashboard-details-date';
import componentStyle from './wedding-card.style';

interface IProps {
  profile: IMicrositeWedding;
}

const WeddingCard = ({ profile }: IProps) => {
  const { t, i18n } = useTranslation('wedding');
  const { locale } = useRouter();
  const { partnerName1, partnerName2, weddingDate, weddingVenue, location } = profile;
  const isUK = locale === RoutingLocales.UK;
  const weddingDateTitle = weddingDate
    ? getDatepickerTitle(i18n, weddingDate, false, false, isUK)
    : '';
  const allDataIsPresent = weddingDateTitle || weddingVenue;
  const venue = weddingVenue ? `${weddingVenue.name}, ${location}` : '';
  const style = componentStyle();

  return (
    <Box data-name="wedding-card">
      <Text style={style.partnerName}>
        {partnerName1 && partnerName2 && (
          <span>
            {partnerName1} & {partnerName2}
          </span>
        )}
        {(!partnerName1 || !partnerName2) && <span>{t('card.title')}</span>}
      </Text>
      {!allDataIsPresent && <Text style={style.font}>{t('card.noDate')}</Text>}
      {weddingVenue && <Text style={style.font}>{venue}</Text>}
      {weddingDate && (
        <Text style={style.font}>
          <DateCard dateTitle={weddingDateTitle} daysLeft={getDaysLeft(weddingDate, isUK)} />
        </Text>
      )}
    </Box>
  );
};

export default WeddingCard;
