import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  font: FelaCSS;
  partnerName: FelaCSS;
}

const styles = (): IStyles => ({
  font: {
    fontDefaultSemiBold: 14,
    lineHeight: '1.43em',
    color: colors.white,
  },

  partnerName: {
    marginBottom: '15px',
    fontDefaultSemiBold: 25,
    color: colors.white,
  },
});

export default styles;
