import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconShortlistFilled = ({ id, color, width = 16.6, style, ...restProps }: IProps) => {
  const viewBoxWidth = 16.6;
  const viewBoxHeight = 16.6;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}>
      <path
        fill={iconColor}
        d="M7.5 16.2v-.1zM11.5 0c-1.2 0-2.3.4-3.2 1.1C7.4.4 6.3 0 5.1 0 2.3 0 0 2.4 0 5.3c0 1.2.4 2.4 1.2 3.4 1.1 1.3 2.2 2.7 3.4 4l2.9 3.5c.2.3.5.4.9.4.3 0 .6-.2.9-.4l6.3-7.5c.7-1 1.2-2.1 1.2-3.4-.2-2.9-2.5-5.3-5.3-5.3z"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconShortlistFilled;
