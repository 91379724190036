import { GetServerSideProps, NextPage } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';
import ErrorPage from 'pages/_error';
import Head from 'components/app/head';
import Header from 'components/header';
import WeddingCard from 'components/wedding-page/wedding-card';
import WeddingPage from 'components/wedding-page/wedding-page';
import { getMicrositeData } from 'lib/api/get-microsite-data';
import { IMicrositeWedding } from 'lib/types';
import nextI18NextConfig from '../../next-i18next.config';

interface IMicrositeInitialProps {
  microsite?: {
    profile: IMicrositeWedding;
    id?: string;
    profileId?: string;
    error: any;
  };
}

const Index: NextPage<IMicrositeInitialProps> = ({ microsite }) => {
  if (!microsite) {
    return null;
  }

  if (microsite.error) {
    return <ErrorPage statusCode={404} />;
  }
  const { profile } = microsite;

  const title = [profile.partnerName1, profile.partnerName2].filter(Boolean).join(' & ');

  return (
    <div>
      <Head title={title || 'Bridebook'} plainTitle={!title} />
      <Header>
        <WeddingCard profile={profile} />
      </Header>
      <WeddingPage profile={profile} />
    </div>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { locale, query } = context;
  const { id } = query;

  const microsite = await getMicrositeData(id);

  return {
    props: {
      ...(await serverSideTranslations(
        locale as string,
        ['guestCollector', 'common', 'wedding', 'countries'],
        nextI18NextConfig,
      )),
      microsite,
    },
  };
};

export default Index;
