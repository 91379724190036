import { RoutingLocales } from '@bridebook/toolbox';

export const getRoutingLocaleForBB = (locale?: string): RoutingLocales => {
  const routingLocales = Object.values(RoutingLocales);

  if (locale && routingLocales.includes(locale as RoutingLocales)) {
    return locale as RoutingLocales;
  }

  return RoutingLocales.UK;
};
