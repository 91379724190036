import { differenceInCalendarDays } from 'date-fns';
import { type Market } from '@bridebook/toolbox/src/gazetteer';
import mapToExactDate from '../datepicker/map-to-exact-date';
import { IDatePickerUI } from '../datepicker/types';

/**
 *  Calculate title ( ie "276 days to go!" )
 *
 * @param {(IDatePickerUI | null)} datePickerUI
 * @returns {number} number of days
 */
const getDaysLeft = (datePickerUI: IDatePickerUI | null, market: Market): number => {
  if (!datePickerUI) return 0;

  const exactDate = mapToExactDate(datePickerUI, market);
  const dateTo = new Date(exactDate || Date.now()).setUTCHours(0, 0, 0, 0);
  const dateFrom = new Date().setUTCHours(0, 0, 0, 0);

  return dateTo ? differenceInCalendarDays(new Date(dateTo), new Date(dateFrom)) : 0;
};

export default getDaysLeft;
