import { useTranslation } from 'next-i18next';
import React from 'react';
import { Box, Col, Container, Row } from '@bridebook/ui';
import IconChecklist from '@bridebook/ui/src/components/svg/icon-checklist';
import IconShortlist from '@bridebook/ui/src/components/svg/icon-shortlist';
import { IMicrositeWedding } from 'lib/types';
import SectionHeading from '../common/section-heading';
import SupplierCard from './supplier-card';
import Tab from './tab';
import Task from './task';
import componentStyles from './wedding-page.style';

interface IProps {
  profile: IMicrositeWedding;
}

const WeddingPage = ({ profile }: IProps) => {
  const { t } = useTranslation('wedding');

  const {
    tasksDone,
    tasksTotal,
    scrapbookSize,
    weddingVenue,
    nextTaskName,
    nextTaskI18nKey,
    location = '',
    checklistCreatedAt,
  } = profile;
  const percentage =
    tasksDone !== tasksTotal ? Math.round(((tasksDone || 0) / (tasksTotal || 1)) * 100) : 100;
  const completed = tasksDone ? percentage : 0;
  let taskName = t('page.wedding.task.notStarted');
  if (checklistCreatedAt && checklistCreatedAt > 0 && completed >= 96) {
    taskName = t('page.wedding.task.married');
  }
  const translate = t; // Creating another variable so `yarn parse-keys` ignores this key
  const nextTaskTranslated = (nextTaskI18nKey && translate(nextTaskI18nKey)) || nextTaskName;
  taskName = completed >= 96 ? taskName : nextTaskTranslated || taskName;
  const saved = t('page.wedding..scrapbook.size', {
    scrapbookSize: scrapbookSize || 0,
  });

  const tabList = [
    {
      title: t('page.wedding.checklist.title'),
      subHeader: t('page.wedding.checklist.completed', {
        completed,
      }),
      color: 'green',
      icon: <IconChecklist color="mint" width={16} />,
    },
    {
      title: t('page.wedding.shortlist.title'),
      subHeader: saved,
      color: 'blue',
      icon: <IconShortlist color="blueCrush" width={14} />,
    },
  ];

  const styles = componentStyles();

  return (
    <Container>
      <Row style={styles.body} columnGap={[2, 5]}>
        <Col lg={4} lgStart={3} md={5} mdStart={2} xsStart={0}>
          <SectionHeading>{t('page.wedding.progress.title')}</SectionHeading>
          <Row>
            {tabList.map((item, index) => (
              <Col xs={6} key={`tab-${index}`}>
                <Tab
                  title={item.title}
                  subHeader={item.subHeader}
                  color={item.color}
                  icon={item.icon}
                />
              </Col>
            ))}
          </Row>
          {weddingVenue && taskName && <Task nextTaskName={taskName} />}
        </Col>
        <Col lg={4} md={5} style={styles.rightSection}>
          {weddingVenue && <SectionHeading>{t('page.wedding.ourVenue')}</SectionHeading>}
          {weddingVenue && <SupplierCard weddingVenue={weddingVenue} location={location} />}
          {!weddingVenue && taskName && (
            <Box style={styles.taskWrapNoVenue}>
              <Task nextTaskName={taskName} />
            </Box>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default WeddingPage;
