import { useTranslation } from 'next-i18next';
import React from 'react';
import IconHeart from '@bridebook/ui/src/components/svg/icon-shortlist-filled';

interface IProps {
  dateTitle: string;
  daysLeft?: number;
}

const DashboardDetailsDate = ({ dateTitle, daysLeft }: IProps) => {
  const { t } = useTranslation('wedding');

  return (
    <span>
      <span>{dateTitle || ''}</span>
      {daysLeft && daysLeft > 0 ? (
        <span>
          &nbsp;
          <IconHeart color="white" width={9} />
          <span>
            &nbsp;
            {t('dashboard.details.date.title', {
              count: daysLeft,
              defaultValue_plural: '{{ count }} days to go!',
            })}
            &nbsp;
          </span>
        </span>
      ) : (
        ''
      )}
    </span>
  );
};

export default DashboardDetailsDate;
