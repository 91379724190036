import { withRouter } from 'next/router';
import React from 'react';
import { Box } from '@bridebook/ui';
import getComponentStyles from './index.style';

interface IProps {
  children?: React.ReactNode;
}

const Header = ({ children }: IProps) => {
  const style = getComponentStyles();

  return (
    <Box data-name="microsites-heading" style={style.wrapper}>
      {children}
    </Box>
  );
};

export default withRouter(Header);
