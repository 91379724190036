import { IMicrositeWedding } from 'microsites/lib/types';
import React from 'react';
import { RoutingLocales } from '@bridebook/toolbox';
import { colors } from '../../themes/variables';
import ImageX from '../bbcommon/imagex/imagex';
import Box from '../fela/Box';
import Text from '../fela/Text';
import IconLocation from '../svg/icon-location';
import getComponentStyle from './supplier-card.style';

type PropTypes = {
  location: string;
  routingLocale: RoutingLocales;
  url?: string;
  weddingVenue: IMicrositeWedding['weddingVenue'];
};

const SupplierCard = ({
  location,
  routingLocale = RoutingLocales.UK,
  url,
  weddingVenue,
}: PropTypes) => {
  const style = getComponentStyle();
  const supplierCard = (
    <Box data-name="supplier-card" style={style.wrapper}>
      <Box style={style.overlay}>
        {/* @ts-expect-error - Type 'string' is not assignable to type 'keyof IColorTypes'. */}
        <IconLocation width={8} height={12} color={colors.white} style={style.iconLocation} />
        <Text style={style.location}>{location}</Text>
      </Box>
      <ImageX
        src={
          weddingVenue
            ? `https://images.bridebook.com/${weddingVenue.thumbnail}?auto=format%2Ccompress&dpr=1&fm=pjpg&fit=crop&w=343&h=170`
            : ''
        }
        w={343}
        h={170}
      />
      <Box style={style.weddingVenue}>
        <Text style={{ fontDefault: 16, color: colors.space }}>
          {weddingVenue && weddingVenue.name}
        </Text>
      </Box>
    </Box>
  );

  if (url) {
    return (
      <Box
        as="a"
        style={{
          textDecoration: 'none',
        }}
        href={`https://bridebook.com/${routingLocale}/wedding-venues/${url}`}
        target="_blank">
        {supplierCard}
      </Box>
    );
  }

  return supplierCard;
};

export default SupplierCard;
