import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
}

const styles = (): IStyles => ({
  wrapper: {
    marginTop: '16px',
    marginBottom: '16px',
    fontDefaultSemiBold: 18,
    lineHeight: '22px',
    color: colors.space,

    desktopDesign: {
      marginTop: '24px',
    },
  },
});

export default styles;
