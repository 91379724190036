import { format as dateFnsFormat, setDate, setMonth } from 'date-fns';
import { de, enGB, enIE, enUS, fr } from 'date-fns/locale';
import type { i18n as I18nType } from 'i18next';
import { RoutingLocales } from '@bridebook/toolbox';
import { getDayOrdinal } from '@bridebook/toolbox/src/datepicker/get-day-ordinal';
import { IDatePickerUI, WeekDayUI } from '@bridebook/toolbox/src/datepicker/types';

/**
 * Returns date-fns language files based on the current next-i18next locale
 */
const getDateFnsLocale = (i18n: I18nType) => {
  switch (i18n.language) {
    case RoutingLocales.DE:
      return de;
    case RoutingLocales.FR:
      return fr;
    case RoutingLocales.IE:
      return enIE;
    case RoutingLocales.UK:
      return enGB;
    default:
      return enUS;
  }
};

const format: (i18n: I18nType) => typeof dateFnsFormat = (i18n) => (date, format, options) =>
  dateFnsFormat(date, format, {
    locale: getDateFnsLocale(i18n),
    ...options,
  });

const getLocalisedSeason = (i18n: I18nType, season: string, isUK: boolean = true): string => {
  switch (season) {
    case 'summer':
      return isUK ? i18n.t('season.summer.name') : i18n.t('season.summer.months');
    case 'autumn':
      return isUK ? i18n.t('season.autum.name') : i18n.t('season.autum.months');
    case 'winter':
      return isUK ? i18n.t('season.winter.name') : i18n.t('season.winter.months');
    case 'spring':
      return isUK ? i18n.t('season.spring.name') : i18n.t('season.spring.months');
    default:
      return '';
  }
};

const getUndecidedDatePickerTitle = (i18n: I18nType): string => i18n.t('datePickerTitle.undecided');

const getTranslatedWeekday = (i18n: I18nType): Record<WeekDayUI, string> => ({
  'Mon-Thurs': i18n.t('options.label.monThurs'),
  Friday: i18n.t('options.label.friday'),
  Saturday: i18n.t('options.label.saturday'),
  Sunday: i18n.t('options.label.sunday'),
});

/**
 * Function `getDatepickerTitle` returns friendly date string from datepicker object
 *
 * This function is copied from
 * `web/lib/datepicker/utils/get-datepicker-title.ts` and not moved to toolbox
 * intentionally as toolbox doesn't support translations.
 */
export const getDatepickerTitle = (
  i18n: I18nType,
  datePickerUI: IDatePickerUI | null,
  dontRemember?: boolean,
  shortMonth: boolean = false,
  isUK: boolean = true,
): string => {
  if (!datePickerUI) return '';
  const formatFn = format(i18n);

  const {
    year,
    yearUndecided,
    month,
    monthUndecided,
    season,
    day,
    weekDay: _weekDay,
    dayUndecided,
  } = datePickerUI;

  const monthString = month
    ? formatFn(setMonth(new Date(), Number(month)), shortMonth ? 'MMM' : 'MMMM')
    : '';

  const localisedSeason = season ? getLocalisedSeason(i18n, season, isUK) : '';

  const monthSeasonWithYear = [monthString || localisedSeason, year].filter((v) => !!v).join(' ');

  const hasMonth = month || season;

  const weekDay = _weekDay ? getTranslatedWeekday(i18n)[_weekDay] : undefined;
  const hasDay = day || weekDay;

  if (
    dontRemember &&
    !(yearUndecided && monthUndecided && dayUndecided) &&
    (yearUndecided || monthUndecided || dayUndecided) &&
    !hasMonth &&
    !hasDay &&
    !year
  ) {
    return '';
  }

  if (yearUndecided && !hasMonth && !hasDay) {
    return dontRemember
      ? i18n.t('datePickerTitle.dontRemember')
      : getUndecidedDatePickerTitle(i18n);
  }

  if (yearUndecided && monthUndecided && weekDay) {
    return i18n.t('datePickerTitle.aWeekday', {
      weekDay,
    });
  }

  if (year && !month && !season && !day && !weekDay) {
    return `${year}`;
  }

  if ((year || yearUndecided) && weekDay) {
    return i18n.t('datePickerTitle.aWeekdayIn', {
      weekDay,
      monthSeasonWithYear,
    });
  }

  if ((year || yearUndecided) && (month || season) && !weekDay && !day) {
    return monthSeasonWithYear;
  }

  if (!year && month && day) {
    const fullDate = setDate(setMonth(new Date(), Number(month)), Number(day));
    return i18n.t('datePickerTitle.dayOfMonth', {
      day: formatFn(fullDate, getDayOrdinal(i18n.language)),
      month: formatFn(fullDate, 'MMMM'),
    });
  }

  if (year && month && day) {
    const fullDate = setDate(new Date(Number(year), Number(month), 1), Number(day));
    return formatFn(fullDate, `${getDayOrdinal(i18n.language)} MMM yyyy`);
  }

  return '';
};
