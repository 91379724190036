import { useTranslation } from 'next-i18next';
import React, { FunctionComponent } from 'react';
import { Box, Checkbox, Text } from '@bridebook/ui';
import componentStyles from './task.style';

type IProps = {
  nextTaskName: string;
};

const TaskCard: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation('wedding');

  const { nextTaskName } = props;
  const style = componentStyles();

  return (
    <Box data-name="task-card" style={style.task}>
      <Text style={style.nextTask}>{t('task.title')}</Text>
      <Box style={style.row} data-name="row">
        <Checkbox disabled />
        <Text style={style.taskName}>{nextTaskName}</Text>
      </Box>
    </Box>
  );
};

export default TaskCard;
