import React from 'react';
import { Box, Text } from '@bridebook/ui';
import componentStyle from './tab.style';

interface IProps {
  title: string;
  icon: React.ReactNode;
  subHeader: string;
  color: string;
}

const Tab = (props: IProps) => {
  const { title, icon, subHeader, color } = props;
  const style = componentStyle({ color });

  return (
    <Box data-name="navigation-tab" style={style.wrapper}>
      <Box style={style.column}>
        {icon}
        <Text style={style.text}>{title.toUpperCase()}</Text>
      </Box>
      <Text style={style.subHeader}>{subHeader}</Text>
    </Box>
  );
};

export default Tab;
