import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';
import ErrorComp from 'components/error';
import nextI18NextConfig from '../next-i18next.config';

interface IProps {
  statusCode: number;
}

const ErrorPage = ({ statusCode }: IProps) => <ErrorComp statusCode={statusCode} />;

export const getStaticProps: GetStaticProps = async (context) => {
  const { locale } = context;

  return {
    props: {
      ...(await serverSideTranslations(locale as string, ['common'], nextI18NextConfig)),
    },
  };
};

export default ErrorPage;
